
import './App.css';
import Mobile from './componets/Mobile-View';
import Desktop from './componets/Desktop';
import { useMediaQuery } from 'react-responsive'

function App() {


  const content = [
    {
      title:"ABOUT US 💡",
      body:`
        EMF-Africa aims to increase everyone's standard by focusing on Servitude & Humanitarian Assistance;
        Peace, Social Justice & Human Rights; Gender & Economic Empowerment among others
      `
      // body:`
      //   EMF-Africa aims to increase everyone's standard
      // `
    },
    {
      title:"VISION 🎯",
      body:"We want to build a world were everyone matters and no one is left behind."
    },
    {
      title:"MISSION 🎯",
      body:"Together we can save the world and create positive impact, Starting with one community at a time"
    },
    {
      title:"Contact Us 📬",
      body:"everyonematters@gmail.com"
    },
  ]

  const isMobile = useMediaQuery({ query: '(max-width: 890px)' })

  return (
      isMobile?<Mobile content={content}/>:<Desktop content={content}/>
  );
}

export default App;
