
import '../App.css';

function Mobile({content}) {
  return (
    <div className={
      `bg-[url('https://ik.imagekit.io/nwqo5veum/emf-Africa/bg-img_14WD7uYPd.jpg?updatedAt=1699452638393')] 
      bg-center bg-no-repeat bg-cover 
      h-[100vh] overflow-x-auto 
      
      `}>
    <div className="bg-[#00000070] h-full p-4">

    {/* Logo Direction */}
    <div className={`fixed flex justify-end items-end top-[5%] mr-4`}>
        <div className="bg-white rounded h-[100%] w-[60%] p-1">
          <img className='h-full w-full' src='https://ik.imagekit.io/nwqo5veum/emf-Africa/VF-1_P7aj2szeE.png?updatedAt=1699452683300' alt="logo" />
        </div>
      </div>

      <div className="flex items-end h-full w-full text-white text-base font-[400] sm:text-lg overflow-x-auto">
        <div className="h-[45vh] flex flex-row ">

          <div className="flex flex-col h-full w-[92vw] sm:w-[95vw]   pl-2 pr-2">
            <div className="border-b-white border-b-4 w-[40%] sm:w-[30%] pl-2 font-montserrat font-[700] text-[1.18rem] sm:text-[1.25rem]">{content[0].title}</div>
            
            <div className="font-josefin pt-2 text-base flex items-center">
              <div className="w-[10vw] h-[2px] bg-[#e0e0e0a3]"></div> 
              <div className="ml-2">We are Launching..</div>
            </div>
            
            <div className="font-josefin pt-2">{content[0].body}</div>
          </div>

          <div className="flex flex-col">
            <div className="flex flex-col w-[92vw] sm:w-[95vw]  pl-2 pr-2 mb-2">
              <div className="border-b-4 w-[30%] sm:w-[20%] pl-2 font-montserrat font-[700] text-[1.18rem] sm:text-[1.25rem]">{content[1].title}</div>
              <div className="font-josefin pt-2">{content[1].body}</div>
            </div>

            <div className="flex flex-col w-[92vw] sm:w-[95vw]  pl-2 pr-2 mt-2 mb-2">
              <div className="border-b-4 w-[35%] sm:w-[30%] pl-2 font-montserrat font-[700] text-[1.18rem] sm:text-[1.25rem]">{content[2].title}</div>
              <div className="font-josefin pt-2">{content[2].body}</div>
            </div>
            <div className="flex flex-col w-[92vw] sm:w-[95vw]  pl-2 pr-2 mt-2">
              <div className="border-b-4 w-[40%] sm:w-[35%] pl-2 font-montserrat font-[700] text-[1.18rem] sm:text-[1.25rem]">{content[3].title}</div>
              <div className="font-josefin pt-2">{content[3].body}</div>
            </div>
          </div>
        </div>
      </div>
        
    </div>




      

    </div>
    
  );
}

export default Mobile;
