
import '../App.css';

function Desktop({content}) {
  return (
    <div className={
      `bg-[url('https://ik.imagekit.io/nwqo5veum/emf-Africa/bg-img_14WD7uYPd.jpg?updatedAt=1699452638393')] 
      bg-center bg-no-repeat bg-cover 
      h-[100vh] overflow-x-auto 
      
      `}>

    <div className="flex flex-row h-full justify-center items-center bg-[#00000070] p-4">
        {/* Logo Direction */}
      <div className={`flex w-[40%] justify-end items-center mr-8 flex-1`}>
        <div className="bg-white rounded h-[20vh]  p-1">
          <img className='h-full w-full' src='https://ik.imagekit.io/nwqo5veum/emf-Africa/VF-1_P7aj2szeE.png?updatedAt=1699452683300' alt="logo" />
        </div>
      </div>

      <div className=" flex flex-col w-[60%] h-full justify-center text-white text-base font-[400] sm:text-lg pr-8">
        <div className="flex flex-col pr-2 mb-8">
            <div className="border-b-white border-b-4 w-[40%] sm:w-[30%] pl-2 font-montserrat font-[700] text-[1.18rem] sm:text-[1.25rem]">{content[0].title}</div>

            <div className="font-josefin pt-2 pb-2 text-base flex items-center">
                <div className="w-[7vw] h-[2px] bg-[#e0e0e0a3]"></div> 
                <div className="ml-2">We are Launching..</div>
            </div>
            
            <div className="font-josefin pt-2">{content[0].body}</div>
        </div>

        <div className="flex flex-col">
            <div className="flex flex-col pr-2 mb-8">
                <div className="border-b-4 w-[34%] sm:w-[24%] pl-2 font-montserrat font-[700] text-[1.18rem] sm:text-[1.25rem]">{content[1].title}</div>
                <div className="font-josefin pt-2">{content[1].body}</div>
            </div>

            <div className="flex flex-col pr-2 mb-8">
                <div className="border-b-4 w-[34%] sm:w-[24%] pl-2 font-montserrat font-[700] text-[1.18rem] sm:text-[1.25rem]">{content[2].title}</div>
                <div className="font-josefin pt-2">{content[2].body}</div>
            </div>

            <div className="flex flex-col pr-2 ">
                <div className="border-b-4 w-[34%] sm:w-[24%] pl-2 font-montserrat font-[700] text-[1.18rem] sm:text-[1.25rem]">{content[3].title}</div>
                <div className="font-josefin pt-2">{content[3].body}</div>
            </div>
        </div>
      </div>
    </div>

      
    </div>
    
  );
}

export default Desktop;
